export function changeText() {
  const sections = document.querySelectorAll('section'),
    text = document.querySelector('.aside-panel__footer__section-name');
  if (text.dataset.change === 'true') {
    sections.forEach((item) => {
      if (item.getBoundingClientRect().top < text.getBoundingClientRect().bottom) {
        if (item.dataset.name) {
          if (text.innerHTML !== item.dataset.name) {
            text.innerHTML = item.dataset.name;
          }
        }
        // else if (text.dataset.name) {
        //   text.innerHTML = text.dataset.name;
        // } else {
        //   text.innerHTML = '';
        // }
      }
    });
  }
}

export function toggleMenu() {
  const menu = document.querySelector('.aside__menu');
  menu.classList.toggle('opened');
}

export function changeBackground() {
  const sections = document.querySelectorAll('section'),
    body = document.body,
    headerWrapper = document.querySelector('.header__wrapper');
  sections.forEach((item) => {
    if (
      item.getBoundingClientRect().top < document.documentElement.clientHeight * 0.3 &&
      Math.abs(item.getBoundingClientRect().top) <
        item.scrollHeight - document.documentElement.clientHeight * 0.3
    ) {
      if (item.dataset.bgcolor) {
        body.style.background = item.dataset.bgcolor;
        if (
          headerWrapper &&
          Math.abs(headerWrapper.getBoundingClientRect().top) >
            headerWrapper.scrollHeight - document.documentElement.clientHeight * 0.3
        ) {
          headerWrapper.classList.add('fade-out-animation');
          headerWrapper.classList.remove('fade-in-animation');
        }
      }
    } else if (
      headerWrapper &&
      Math.abs(headerWrapper.getBoundingClientRect().top) <
        headerWrapper.scrollHeight - document.documentElement.clientHeight * 0.3
    ) {
      headerWrapper.classList.remove('fade-out-animation');
      headerWrapper.classList.add('fade-in-animation');
    }
  });
}
