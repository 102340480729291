export function labelsAnimation() {
  const labels = document.querySelectorAll('.form-label__item label'),
    data__inputs = document.querySelectorAll('.data__input');
  if (labels && data__inputs) {
    data__inputs.forEach((item, index) => {
      item.addEventListener('focus', () => {
        labels[index].classList.add('active');
      });
      item.addEventListener('blur', () => {
        if (item.value === '') {
          labels[index].classList.remove('active');
        }
      });
    });
  }
}
