export function lightUpOnScroll(elements) {
  elements.forEach((item) => {
    //top
    // if (item.getBoundingClientRect().top < 118 && item.getBoundingClientRect().top > 0) {
    //   lightOffEverything(elements);
    //   item.classList.add('active');
    // }
    //bottom
    // if (item.getBoundingClientRect().bottom < 1053 && item.getBoundingClientRect().bottom > 853) {
    //   lightOffEverything(elements);
    //   item.classList.add('active');
    // }
    //middle
    if (
      item.getBoundingClientRect().bottom < 1053 / 2 &&
      item.getBoundingClientRect().bottom > 853 / 2
    ) {
      lightOffEverything(elements);
      item.classList.add('active');
    }
  });
}

function lightOffEverything(elements) {
  elements.forEach((item) => {
    item.classList.remove('active');
  });
}
