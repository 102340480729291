export default function extendText(target) {
  target.classList.toggle('clicked');
  const text = target.nextElementSibling;
  if (text.style.height === '0px' || window.getComputedStyle(text).height === '0px') {
    text.style.height = `${text.scrollHeight}px`;
  } else {
    text.style.height = `${text.scrollHeight}px`;
    window.getComputedStyle(text, null).getPropertyValue('height');
    text.style.height = '0px';
  }
}

export function extendOnHover(target) {
  const text = target.nextElementSibling;
  text.classList.add('hovered');
  if (text.style.height === '0px' || window.getComputedStyle(text).height === '0px') {
    text.style.height = `${text.scrollHeight}px`;
  }
}
