export default function Slider(slidesSelector, dotsSelector) {
  const slides = document.querySelectorAll(slidesSelector),
    dots = document.querySelectorAll(dotsSelector);
  window.addEventListener('click', (e) => {
    if (e.target.closest(dotsSelector)) {
      dots.forEach((item, index) => {
        if (e.target.closest(dotsSelector) === item) {
          closeAllSlides(slides, dots);
          openSlide(slides, dots, index);
        }
      });
    }
  });
}

function closeAllSlides(slides, dots) {
  slides.forEach((item) => {
    item.classList.remove('active');
  });
  dots.forEach((item) => {
    item.classList.remove('active');
  });
}

function openSlide(slides, dots, n) {
  slides[n].classList.add('active');
  dots[n].classList.add('active');
}
