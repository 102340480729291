export function openForm(e) {
  //   e.preventDefault();
  const inner = document.querySelector('.careers-job__inner');
  inner.classList.toggle('opened');
}

export function sendCareersForm(e) {
  e.preventDefault();
  const formData = {
    first_name: e.target[0].value,
    last_name: e.target[1].value,
    email: e.target[2].value,
    phone: e.target[3].value,
    country: e.target[4].value,
    state: e.target[5].value,
    city: e.target[6].value,
    cv_file: e.target[7].value,
    form_of_cooperation: e.target[8].value,
  };
  console.log(formData);
}
