import { isMobileResolution } from './helpers/utils';
import { changeText, toggleMenu, changeBackground } from './helpers/aside-panel';
import { openForm, sendCareersForm } from './helpers/careers';
import { sendMessage, sendForm } from './helpers/contact-us';
import extendText, { extendOnHover } from './helpers/extendBlocks';
import { changeImageOnHover } from './helpers/images-on-hover';
import { lightUpOnScroll } from './helpers/light-up-on-scroll';
import { labelsAnimation } from './helpers/labels';
import Slider from './helpers/slider';

//text change for aside panel
window.addEventListener('scroll', changeText);
window.addEventListener('scroll', changeBackground);
changeText();
changeBackground();
//open & close aside menu

const menuOpen = document.querySelector('.aside-panel__header'),
  menuClose = document.querySelector('.aside__menu__close'),
  menuMobileClose = document.querySelector('.aside__menu__close-mobile');

if (menuClose && menuClose) {
  menuOpen.addEventListener('click', toggleMenu);
  menuClose.addEventListener('click', toggleMenu);
  menuMobileClose.addEventListener('click', toggleMenu);
}

// //email form in contact us
// const contactUsForm = document.querySelector('.contact-us__form');

// if (contactUsForm) {
//   contactUsForm.addEventListener('submit', sendMessage);
// }

// //form on contact us page

// const contactUsPageForm = document.querySelector('.contact-us_page__form');

// if (contactUsPageForm) {
//   contactUsPageForm.addEventListener('submit', sendForm);
// }

//extend text side

window.addEventListener('click', (e) => {
  if (e.target.closest('.click-to-extend')) {
    extendText(e.target.closest('.click-to-extend'));
  }
});

window.addEventListener('mouseover', (e) => {
  if (e.target.closest('.hover-to-expand')) {
    extendOnHover(e.target.closest('.hover-to-expand'));
  }
});

const hiddenBlocks = document.querySelectorAll('.hidden-block');
if (hiddenBlocks) {
  hiddenBlocks.forEach((item) => {
    item.addEventListener('transitionend', () => {
      if (window.getComputedStyle(item).height !== '0px') {
        item.style.height = 'auto';
      }
    });
  });
}

// change pages on news page

Slider('.news__articles__pages__page', '.news__articles__controllers__controller');

//image on hover ABoutUsPage
if (!isMobileResolution()) {
  const examplesOnHoverBlocks = document.querySelectorAll('.examples__list');
  examplesOnHoverBlocks.forEach((item) => {
    changeImageOnHover(item);
  });
}

//Header Sliders

Slider('.header-slide', '.header-main-dot');
Slider('.news-slide', '.header-news-dot');

// careers

const openCareersForm = document.querySelector('#open-careers-form'),
  closeCareersForm = document.querySelector('#close-careers-form');
if (openCareersForm) {
  openCareersForm.addEventListener('click', openForm);
  closeCareersForm.addEventListener('click', openForm);
}

const sendCareersFormButton = document.querySelector('#send-registration-careers');
if (sendCareersFormButton) {
  sendCareersFormButton.addEventListener('submit', sendCareersForm);
}

//light up elements on scroll

const mainPageRows = document.querySelectorAll('.portfolio__text-side__list__row');
if (mainPageRows) {
  window.addEventListener('scroll', () => lightUpOnScroll(mainPageRows));
}

//labels

labelsAnimation();
