export function changeImageOnHover(block) {
  const elements = block.querySelectorAll('.examples__list__item'),
    imageBlock = block.querySelector('.bg-element');
  elements.forEach((item) => {
    item.addEventListener('mouseover', () => {
      imageBlock.src = item.dataset.imageurl;
      imageBlock.classList.add('active');
    });
    item.addEventListener('mouseout', () => {
      imageBlock.classList.remove('active');
    });
  });
}
